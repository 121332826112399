
<template>
  <div class="recharge">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    <div v-show="this.flg" class="recharge_main">
      <div class="recharge_main_top">
        <div class="recharge_main_title">
          <van-image :src="showImage" />
          <span>
            <span>当前充值:</span>
            <span class="name">{{ name }}</span>
          </span>
        </div>
        <div class="recharge_main_input">
          <van-cell-group>
            <van-field v-model="account" label="充值账号：" placeholder="请输入充值账号" required @input="input" />
          </van-cell-group>
        </div>
        <div class="type" v-if="this.specValueflg != []">
          <div class="type_title">
            <span>充值类型</span>
          </div>
          <van-row>
            <van-col span="7" class="activeType" v-for="(item, index) in specValue1" :key="index"
              :class="{ 'active': memberType == index }" @click="clickcol($event, index, item)">
              <span>
                <i class="iconfont icon-huiyuan4"></i>&nbsp;{{ item.specValue1 }}</span>
            </van-col>
          </van-row>
        </div>
        <div class="recharge_main_prcie">
          <div class="price">
            <div class="price_title">
              <span>充值面额</span>
            </div>
            <van-grid :column-num="3" :gutter="15" center>
              <van-grid-item v-for="(value, index) in skus" :key="index" @click="clickGrid($event, index, value)"
                :class="{ 'priceActive': priceIndex == index }">
                <p style="font-size:1.5em; color:#B1855D">{{ value.specValue2 }}</p>
                <span
                  style="font-size:1.2em; color:#999999;font-weight:300;text-decoration:line-through;margin:0.4em 0;">官网价￥{{
                  value.faceValue
                  }}</span>
                <span style="font-size:2em; color:#B1855D;font-weight:600;">￥{{ (value.retailPrice).toFixed(2) }}</span>
              </van-grid-item>
            </van-grid>
          </div>
        </div>
        <div class="tip">
          <div class="tip_title">
            <span>购买须知</span>
          </div>
          <div class="tip_text" v-html="this.instructions"></div>
        </div>
      </div>
    </div>
    <div class="recharge_main_foot">
      <van-tabbar>
        <p>实际付款：<span>￥{{ price }}</span></p>
        <van-button round type="info" size="large" @click="payment">立即充值</van-button>
      </van-tabbar>
    </div>
    <div class="sj_order" @click="order">
      <van-icon name="orders-o" />
      <p>订单</p>
    </div>
    <van-dialog v-model="accountDialog" show-cancel-button confirm-button-text="确认无误" cancel-button-text="更改号码"
      :before-close="confirmed">
      <div style=" display: flex;flex-direction: column;align-items: center; margin:2.2em 0; line-height:3em;">
        <p>您当前的充值号码为：</p>
        <p style="font-size:1.6em; font-weight:600">{{ account }}</p>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { api_gooddetails, api_micropay, api_jsSdkconfig, api_phone_token } from "@/api/api.js"
import { getQueryVariable, } from "@/util/utils.js"
import wx from 'weixin-js-sdk'
export default {
  data () {
    return {
      account: '',
      showImage: '',
      name: '',
      price: "",
      skus: "",
      accountDialog: false,
      instructions: "",
      memberType: "0",
      priceTypeId: "",
      priceIndex: "0",
      retailPrice: "",
      show: false,
      radio: "",
      goodsId: '',
      specValue1: [],
      specValueflg: [],
      priceData: [],
      supplierId: '',
      flg: true,
      linkNumber: "",
      location: "",
      type: 1,
      appName: "",
      packageName: "",
    }
  },
  created () {
    this.location = window.location.href
    sessionStorage.setItem("pageId", '1')
    if (sessionStorage.getItem('key') != null) {
      sessionStorage.removeItem('key')
      this.name = this.$route.query.name
      this.goodsId = this.$route.query.id
      this.supplierId = this.$route.query.supplierId
    } else {
      this.name = getQueryVariable('name')
      this.goodsId = getQueryVariable("id")
      this.supplierId = getQueryVariable("supplierId")
      if (getQueryVariable("link") == false) {
        this.linkNumber = ""
      } else {
        this.linkNumber = getQueryVariable("link")
      }
    }
    if (this.isIos()) {
      this.type = 2
    } else {
      this.type = 1
    }
    localStorage.setItem('redirect_uri', window.location.href)
    this.priceType
    this.getGooddeTails()
    this.getjsSDK()
  },
  methods: {
    getjsSDK () {
      api_jsSdkconfig().then(res => {
        if (res.data.code == 0) {
          wx.config({
            debug: false,
            appId: res.data.data.appId,
            timestamp: res.data.data.timestamp,
            nonceStr: res.data.data.noncestr,
            signature: res.data.data.signature,
            jsApiList: ['chooseWXPay']
          })
        }
      })
    },
    getGooddeTails () {
      api_gooddetails(this.goodsId, this.supplierId).then(res => {
        if (res.data.code == 0) {
          this.name = res.data.data.name
          this.specValue1 = JSON.parse(JSON.stringify(res.data.data.details))
          console.log('this.specValue1', this.specValue1)
          this.showImage = res.data.data.showImage
          let message = res.data.data.instructions; //data是你从json数组里面拿到的字符串
          this.instructions = message.replace(/\n/g, "<br/>");
          for (const key in this.specValue1) {
            this.specValueflg = this.specValue1[key].specValue1
            if (this.memberType == key) {
              this.skus = this.specValue1[key].skus
              for (const key in this.skus) {
                if (this.priceIndex == key) {
                  this.price = (this.skus[key].retailPrice).toFixed(2)
                  this.priceTypeId = this.skus[key].id
                }
              }
            }
          }
        } else {
          this.flg = false
        }
      })
    },

    onClickLeft () {
      sessionStorage.setItem('key', 'value')
      sessionStorage.removeItem('pageId')
      this.$router.push({
        path: '/goodslist',
        query: {
          supplierId: this.supplierId,
        }
      })
    },
    showPopup () {
      this.show = true;
    },
    clickcol (e, index, item) {
      this.memberType = index
      this.skus = item.skus
      for (const key in this.specValue1) {
        this.specValueflg = this.specValue1[key].specValue1
        if (this.memberType == key) {
          this.skus = this.specValue1[key].skus
          for (const key in this.skus) {
            if (this.priceIndex == key) {
              this.price = (this.skus[key].retailPrice).toFixed(2)
              this.priceTypeId = this.skus[key].id
            }
          }
        }
      }
    },
    clickGrid (e, index, value) {
      console.log(value)
      this.price = (value.retailPrice).toFixed(2)
      this.priceIndex = index
      this.priceTypeId = value.id
    },
    input () {
      if (this.account) {
        this.disabled = false
      }
    },
    payment () {
      if (this.account == '') {
        this.$toast('请输入充值账号');
      } else {
        this.accountDialog = true
      }
    },
    confirmed (e, done) {
      if (e === 'confirm') {
        const phone = {
          phone: this.account
        }
        api_phone_token(phone).then(res => {
          if (res.data.code == 0) {
            sessionStorage.setItem('accessToken', 'yh' + ' ' + res.data.data.accessToken)
            const params = {
              account: this.account,
              sendEmail: null,
              remark: null,
              goodsId: this.goodsId,
              skuId: this.priceTypeId,
              supplierId: this.supplierId,
              activityId: this.activityIdId,
              linkNumber: this.linkNumber,
              sourceUrl: this.location,
              tradeType: 1,
              type:this.type,
            }
            console.log('params', params)
            api_micropay(params).then(res => {
              console.log(res)
              if (res.status == 200) {
                console.log(res.data)
                sessionStorage.removeItem('accessToken'); ('token')
                console.log(res.data)
                // window.location.href = res.data.mwebUrl
              } else {
                this.$toast.fail('支付失败' + res.data.message);
                return done()
              }
            }).catch((err) => {
              alert(err);
            })
          }
        })
      }
      else {
        this.accountDialog = false
        return done()
      }
    },
    order () {
      this.$router.push({
        path: '/queryorder',
        query: {
          supplierId: this.supplierId,
        }
      })
    },
    isIos () {
      var u = navigator.userAgent;
      var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      return isIos
    },
    isAndroid () {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
      return isAndroid
    }
  },
  computed: {
  }
}
</script>
<style lang="scss" scoped>
.recharge {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;

  .recharge_main {
    height: 100%;
    overflow: scroll;

    .recharge_main_top {
      height: 2em;
      background: #508fea;
      padding: 1.5em 1em 1.5em 1em;

      .recharge_main_title {
        line-height: 6em;
        background: #ffffff;
        border-radius: 2.5em 2.5em 0.3em 0.3em;
        padding: 0.5em 2em 0 2em;
        display: flex;
        align-items: center;

        .van-image {
          width: 3.5em;
          height: 3.5em;
        }

        span {
          font-size: 1.2em;
          font-weight: 500;
          color: #000000;
          margin: 0 0.2em;

          .name {
            font-size: 1.4em;
            font-weight: 500;
            color: #5b70e7;
          }
        }
      }

      .recharge_main_input {
        margin-top: 1.2em;

        span {
          font-size: 1.5em;
          color: #000000;
          margin-left: 1em;
          line-height: 3em;
          display: flex;
          align-items: center;

          .icon-huiyuan3 {
            font-size: 2.5rem;
          }
        }
      }

      .type {
        margin-top: 1.2em;
        padding: 0.5em 1.5em 0 1.5em;
        background: #ffffff;

        .type_title {
          line-height: 1.5em;
          display: flex;
          align-items: center;
          font-size: 1.5em;
          margin-bottom: 0.5em;

          div {
            width: 0.5em;
            height: 0.5em;
            background: #2c3037;
            border-radius: 50%;
            margin-right: 0.8em;
          }
        }

        .activeType {
          line-height: 2.5em;
          background: #f3eadb;
          border-radius: 0.3em;
          font-size: 1.5em;
          margin: 0.5em;
          margin-right: 0.6em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          justify-content: center;
          color: #ecbb73;

          .iconfont {
            font-size: 1.2em;
          }
        }

        .active {
          line-height: 2.5em;
          background: #2c3037;
          border-radius: 0.3em;
          font-size: 1.5em;
          margin: 0.5em 0;
          margin-right: 0.6em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          justify-content: center;
          color: #ecbb73;
        }
      }

      .recharge_main_prcie {
        margin-top: 1.2em;
        padding-bottom: 1em;
        background: #ffffff;

        .price {
          .price_title {
            padding: 1em 1em 1em 1em;
            line-height: 1.5em;
            display: flex;
            align-items: center;
            font-size: 1.5em;

            div {
              width: 0.5em;
              height: 0.5em;
              background: #2c3037;
              border-radius: 50%;
              margin-right: 0.8em;
            }

            .van-grid {
              padding-left: 0 !important;
            }
          }
        }

        .active {
          line-height: 2.5em;
          background: #2c3037;
          border-radius: 0.3em;
          font-size: 1.5em;
          margin: 0.5em 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          justify-content: center;
          color: #ecbb73;
          margin-right: 1em;
        }

        ::v-deep .van-grid-item__content {
          padding: 16px 0px !important;
          background-color: rgb(241, 241, 241) !important;
        }

        .priceActive {
          ::v-deep .van-grid-item__content {
            padding: 16px 0px !important;
            background-color: #f3e5cd !important;
          }
        }
      }

      .tip {
        margin-top: 1.2em;
        padding: 0.5em 1.5em 0.5em 1.5em;
        background: #ffffff;
        border-radius: 0.3em 0.3em 2em 2em;

        .tip_title {
          margin: 0.5em 0;
          line-height: 1.5em;
          display: flex;
          align-items: center;
          font-size: 1.5em;

          div {
            width: 0.5em;
            height: 0.5em;
            background: #2c3037;
            border-radius: 50%;
            margin-right: 0.8em;
          }

          p {
            margin-bottom: 0.5rem;
          }
        }

        .tip_text {
          margin: 0.2em 0;
          padding-left: 0.5em;
          font-size: 1.3em;
          font-weight: 300;
          color: #999999;
          line-height: 2em;
        }
      }
    }
  }

  .recharge_main_foot {
    height: 5em;
    margin-top: 1.5em;

    .van-tabbar {
      align-items: center;
      justify-content: flex-end;
      position: relative;
      height: 5em;

      p {
        font-size: 1.8em;
        margin-right: 1em;

        span {
          color: red;
        }
      }

      .van-button--large {
        height: 2.5em;
        margin: 0.6em 0.3em 0.6em 0.2em;
        border: none;
        width: 45%;
        background: linear-gradient(to right, #ff8917, #ff6034);

        .van-button__text {
          font-size: 1em;
        }
      }
    }
  }

  .van-cell-group {
    .van-cell {
      display: flex;
      align-items: center;

      .icon-weixinzhifu {
        font-size: 1.5em;
      }

      .icon-umidd17 {
        font-size: 1.5em;
      }

      span {
        font-weight: 300;
        margin-left: 0.3em;
        font-size: 1.1em;
      }
    }
  }

  .instructions {
    word-break: normal;
  }
}

.sj_order {
  width: 5.5em;
  height: 5.5em;
  position: fixed;
  display: flex;
  border-radius: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: red;
  box-shadow: 0px 0px 7px rgb(0 0 0 / 10%);
  background: #fff;
  right: 5%;
  top: 10%;
  border-radius: 50%;

  .van-icon {
    font-size: 2.5em;
  }
}
</style>

